import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const SecondPage = () => (
  <Layout>
    <SEO title="Classes" />
    <div style={{ paddingLeft: "2rem" }}>
      <h3>Completed Technical Classes at MIT: </h3>
      <p>
        2.001 - Mechanics and Materials I <br />
        2.002 - Mechanics and Materials II <br />
        2.003 - Dynamics and Controls I <br />
        2.004 - Dynamics and Controls II <br />
        2.005 - Thermal Fluids Engineering I <br />
        2.006 - Thermal Fluids Engineering II <br />
        2.007 - Design and Manufacturing I <br />
        2.008 - Design and Manufacturing II <br />
        2.00B - Toy Product Design <br />
        2.086 - Numerical Computation for Mechanical Engineers <br />
        2.671 - Measurement and Instrumentation <br />
        2.12 - Introduction to Robotics (Design, Control, Computer Vision,
        Machine Learning) <br />
        6.01 - Introduction to EECS via Robotics <br />
        2.s009 - Explorations in Product Design (Capstone for Mechanical
        Engineering Majors) <br />
        2.70 - Precision Product Design <br />
        2.74 - Bio-Inspired Robotics <br />
      </p>

      <h3>Completed Music Classes:</h3>
      <p>
        21M.011 - Introduction to Western Music <br />
        21M.235 - Baroque and Classical Music <br />
        21M.294 - Popular Musics of the World <br />
        21M.302 - Harmony and Counterpoint II <br />
        21M.303 - Writing in Tonal Forms I <br />
        21M.340 - Jazz Harmony and Arranging <br />
        21M.490 - Emerson Scholar Solo Recital <br />
        21M.445 - Chamber Music Society (4 semesters) <br />
        21M.500 - Advanced Seminar in Music (Capstone for Music Majors)
      </p>
    </div>
  </Layout>
)

export default SecondPage
